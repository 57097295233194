import * as React from 'react'
import tw, { styled } from 'twin.macro'
import dayjs from 'dayjs'
import { Container, Header } from 'semantic-ui-react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/Layouts/cakeDefault'

const VoucherInfoContainer = styled.div`
  position: absolute;
  display: block;
  top: 28%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (min-width: 640px) {
    top: 23%;
  }
`

const GroupBox = styled.div`
  display: flex;
  align-items: center;
  /* margin-top: 10px; */
`

const InputLabel = tw.div`inline-block py-1 px-2 font-semibold
  text-black w-24 md:w-28
  text-xs xs:text-base sm:text-lg`

const TextBox = tw.div`py-1 px-2 w-2/3 bg-savor-cake-secondary-green-matcha-100 rounded-[10px] font-semibold
text-base xs:text-xl sm:text-2xl`

export default function VoucherPage({ location }) {
  const [fetching, setFetching] = React.useState(false)
  const [voucher, setVoucher] = React.useState(null)
  const [error, setError] = React.useState('')
  const [image, setImage] = React.useState()

  React.useEffect(() => {
    // get search param from location
    const searchParams = new URLSearchParams(location.search)
    const couponCode = searchParams.get('couponCode')
    if (!couponCode) {
      setError('Cần có thông tin mã Code')
      return
    }
    // fetch voucher info from server
    async function fetchVoucher() {
      const data = await fetch(
        `${process.env.GATSBY_API_URL}/vouchers/search?couponCode=${couponCode}`
      )
      const voucher = await data.json()
      if (voucher && voucher.message) {
        setError(voucher.message)
        return
      }
      if (!voucher || !voucher._id) {
        setError('Không nhận được dữ liệu về voucher')
        return
      }
      setVoucher(voucher)
    }
    setFetching(true)
    fetchVoucher()
      .catch((error) => {
        console.log(error)
        setError(error.message)
      })
      .finally(() => {
        setFetching(false)
      })
  }, [location.search])

  React.useEffect(() => {
    if (voucher && voucher.voucherType === 'DISCOUNT_CAKE_ORDER_10_PERCENT') {
      setImage(
        <StaticImage
          src="../assets/images/voucher-by-qty.png"
          alt="Voucher giảm giá bánh sinh nhật"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'cover',
            borderRadius: '5%',
            position: 'relative',
          }}
        />
      )
    }
    if (voucher && voucher.voucherType === 'DISCOUNT_CAKE_ORDER') {
      setImage(
        <StaticImage
          src="../assets/images/get-voucher/giam-30k-cake.jpg"
          alt="Voucher giảm giá bánh sinh nhật"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'cover',
            borderRadius: '5%',
            position: 'relative',
          }}
        />
      )
    }
    // if (voucher && voucher.voucherType === 'FREE_GIFT_SAVOR_CAKE') {
    //   setImage(
    //     <StaticImage
    //       src="https://s3.kstorage.vn/savor-web/src/assets/images/get-voucher/tang-bo-dao-dia.png"
    //       src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/accessories/V8778-bo-dao-dia-giay-ko-nen.png"
    //       alt="Bộ dao, dĩa, đĩa nhựa, nến đựng trong túi giấy Savor Cake"
    //       style={{
    //         maxWidth: '50%',
    //         maxHeight: '50%',
    //         objectFit: 'cover',
    //         borderRadius: '5%',
    //         position: 'relative',
    //       }}
    //     />
    //   )
    // }
  }, [voucher])

  return (
    <Layout endPoint={'voucher'} title={'Savor Cake | Xem Voucher'}>
      {fetching && (
        <Container style={{ marginTop: 20 }}>
          <Header>Đang tải voucher...</Header>
        </Container>
      )}
      {error && (
        <Container style={{ marginTop: 20 }}>
          <Header>Lỗi khi tải voucher</Header>
          <p>{error}</p>
        </Container>
      )}

      {!error && voucher && (
        <Container style={{ marginTop: 20 }} text>
          <Header tw="text-center text-savor-cake-primary-green-avocado-0!">
            Đổi voucher thành công!
          </Header>
          <p tw="text-center">
            Hãy chụp ảnh màn hình dưới đây và inbox cho chúng tôi trong lần đặt
            bánh online tiếp theo nhé. (Có thể chia sẻ cho bạn bè, người thân)
          </p>
          <Voucher voucher={voucher} image={image} />
        </Container>
      )}
    </Layout>
  )
}

const VoucherInfo = ({ voucher }) => {
  return (
    <div tw="w-2/3 mx-auto">
      <GroupBox>
        <InputLabel>Mã Voucher</InputLabel>
        <TextBox>{voucher.couponCode}</TextBox>
      </GroupBox>
      <GroupBox>
        <InputLabel>Hạn sử dụng</InputLabel>
        <TextBox>{dayjs(voucher.expiryDate).format('DD-MM-YYYY')}</TextBox>
      </GroupBox>
    </div>
  )
}

const Voucher = ({ voucher, image }) => {
  return (
    <>
      {voucher.voucherType !== 'FREE_GIFT_SAVOR_CAKE' ? (
        <div tw="relative text-center m-auto w-9/10 h-full">
          {image}
          <VoucherInfoContainer tw="sm:pt-10 w-full">
            <div tw="text-center text-savor-cake-primary-green-avocado-0 font-savor-cake-primary-lexend mb-4">
              <p tw="uppercase font-semibold text-lg sm:text-2xl lg:text-3xl m-0 mb-1 sm:mb-3">
                Bạn nhận được
              </p>
              <p tw="uppercase font-extrabold text-xl sm:text-3xl lg:text-4xl mb-1 sm:mb-3">
                voucher giảm giá {voucher.quantity}%
              </p>
              <p tw="text-sm sm:text-base mb-1 sm:mb-3 px-5 sm:px-0">
                Áp dụng cho tổng hóa đơn của đơn hàng tiếp theo
              </p>
            </div>
            <VoucherInfo voucher={voucher} />
          </VoucherInfoContainer>
        </div>
      ) : null}
    </>
  )
}
